var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('image-uploader',{staticClass:"mt-6 mb-1",attrs:{"label":_vm.$t('object-type-form.icon')},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}}),_c('form',{ref:"form",staticClass:"form py-1",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{attrs:{"label":_vm.$t('object-type-form.name'),"placeholder":_vm.$t('object-type-form.name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{staticClass:"mt-4",attrs:{"id":"client-select","url":"organizations","query":_vm.computedQuery,"option-label-name":"name","option-value-name":"id","label":_vm.$t('object-type-form.client'),"placeholder":_vm.$t('object-type-form.client-placeholder'),"error-message":errors[0],"disabled":_vm.isEdit || _vm.isLoading,"required":""},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,true)}),(_vm.client)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input',{key:_vm.forceUpdateBrands,staticClass:"mt-4",attrs:{"id":"brand-select","url":"brands","query":{ clientId: _vm.client.id },"option-label-name":"name","option-value-name":"id","label":_vm.$t('object-type-form.brand'),"placeholder":_vm.$t('object-type-form.brand-placeholder'),"error-message":errors[0],"disabled":_vm.isEdit || _vm.isLoading,"required":""},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":{ regex: _vm.hexColorRegex, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('color-input',{staticClass:"mt-4",attrs:{"id":"color-input-2","label":_vm.$t('object-type-form.marker-color'),"required":"","disabled":_vm.isLoading || _vm.showCustomIcon,"is-error":errors[0]},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})]}}],null,true)})],1),_c('switch-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('object-type-form.custom-icon'),"label-position":_vm.LabelPosition.RIGHT,"tooltip-content":_vm.$t('object-type-form.custom-icon-tooltip')},model:{value:(_vm.showCustomIcon),callback:function ($$v) {_vm.showCustomIcon=$$v},expression:"showCustomIcon"}}),(_vm.showCustomIcon)?_c('image-uploader',{staticClass:"mt-6 mb-1",attrs:{"preview-dimensions":"h-46 w-46","accepted-files":".svg","without-label":"","fill":""},on:{"read-file":_vm.handleReadFile},model:{value:(_vm.iconUrl),callback:function ($$v) {_vm.iconUrl=$$v},expression:"iconUrl"}}):_vm._e(),_c('switch-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('object-type-form.budgeted-object'),"label-position":_vm.LabelPosition.RIGHT},model:{value:(_vm.isBudgetEnabled),callback:function ($$v) {_vm.isBudgetEnabled=$$v},expression:"isBudgetEnabled"}}),_c('info-bar',{staticClass:"border-2 border-yellow rounded-md p-2 px-4 mt-4",attrs:{"show":_vm.showBudgetWarning,"no-spacing":""}},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('icon',{staticClass:"text-gray-darker",attrs:{"icon":_vm.mdiAlertOctagonOutline}}),_c('span',{staticClass:"text-xs text-left"},[_vm._v(" "+_vm._s(_vm.$t('object-type-form.budgeted-object-warning'))+" ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }