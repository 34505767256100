<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <image-uploader
      v-model="imageUrl"
      :label="$t('object-type-form.icon')"
      class="mt-6 mb-1"
    />
    <form
      :id="id"
      ref="form"
      class="form py-1"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="name"
          :label="$t('object-type-form.name')"
          :placeholder="$t('object-type-form.name-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
        ></text-input>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <select-input
          id="client-select"
          v-model="client"
          url="organizations"
          :query="computedQuery"
          option-label-name="name"
          option-value-name="id"
          :label="$t('object-type-form.client')"
          :placeholder="$t('object-type-form.client-placeholder')"
          :error-message="errors[0]"
          :disabled="isEdit || isLoading"
          required
          class="mt-4"
        />
      </ValidationProvider>
      <ValidationProvider v-if="client" v-slot="{ errors }" rules="required">
        <select-input
          id="brand-select"
          :key="forceUpdateBrands"
          v-model="brand"
          url="brands"
          :query="{ clientId: client.id }"
          option-label-name="name"
          option-value-name="id"
          :label="$t('object-type-form.brand')"
          :placeholder="$t('object-type-form.brand-placeholder')"
          :error-message="errors[0]"
          :disabled="isEdit || isLoading"
          required
          class="mt-4"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ regex: hexColorRegex, required: true }"
      >
        <color-input
          id="color-input-2"
          v-model="color"
          :label="$t('object-type-form.marker-color')"
          required
          :disabled="isLoading || showCustomIcon"
          :is-error="errors[0]"
          class="mt-4"
        />
      </ValidationProvider>
    </form>
    <switch-input
      v-model="showCustomIcon"
      class="mt-5"
      :label="$t('object-type-form.custom-icon')"
      :label-position="LabelPosition.RIGHT"
      :tooltip-content="$t('object-type-form.custom-icon-tooltip')"
    />
    <image-uploader
      v-if="showCustomIcon"
      v-model="iconUrl"
      class="mt-6 mb-1"
      preview-dimensions="h-46 w-46"
      accepted-files=".svg"
      without-label
      fill
      @read-file="handleReadFile"
    />
    <switch-input
      v-model="isBudgetEnabled"
      class="mt-5"
      :label="$t('object-type-form.budgeted-object')"
      :label-position="LabelPosition.RIGHT"
    />
    <info-bar
      class="border-2 border-yellow rounded-md p-2 px-4 mt-4"
      :show="showBudgetWarning"
      no-spacing
    >
      <div class="flex gap-2 items-center">
        <icon :icon="mdiAlertOctagonOutline" class="text-gray-darker" />
        <span class="text-xs text-left">
          {{ $t('object-type-form.budgeted-object-warning') }}
        </span>
      </div>
    </info-bar>
  </ValidationObserver>
</template>

<script>
import {
  TextInput,
  SelectInput,
  SwitchInput,
  LabelPosition,
} from '@/components/Inputs';
import ImageUploader from '@/components/Uploader/ImageUploader.vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { regex, required } from 'vee-validate/dist/rules';
import { CLIENT_TYPE } from '@/store/modules/user';
import { hexColorRegex } from '@/util/form';
import ColorInput from '@/components/FormElements/ColorInput';
import {
  CUSTOM_MARKER_OPTIONS,
  DEFAULT_OBJECT_MARKER_COLOR,
} from '@/components/Map';
import { readFileAsText } from '@/util/files';
import { uploadTemporaryFile } from '@/api/requests/files';
import { drawSvgInsideRectangle, svgStringToFile } from '@/util/svg';
import { InfoBar } from '@/components/InfoBar';
import { Icon } from '@/components/Icon';
import { mdiAlertOctagonOutline } from '@mdi/js';

export default {
  components: {
    Icon,
    InfoBar,
    SelectInput,
    SwitchInput,
    TextInput,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    ColorInput,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: null,
    },
    clear: Boolean,
    isLoading: Boolean,
    isEdit: Boolean,
  },
  data() {
    return {
      name: '',
      imageUrl: '',
      iconUrl: '',
      showCustomIcon: false,
      isBudgetEnabled: false,
      client: null,
      brand: null,
      color: DEFAULT_OBJECT_MARKER_COLOR,
      forceUpdateBrands: 0,
    };
  },
  computed: {
    computedQuery() {
      return {
        type: CLIENT_TYPE,
      };
    },
    showBudgetWarning() {
      return this.content?.isBudgetEnabled && !this.isBudgetEnabled;
    },
  },
  watch: {
    clear(val) {
      if (val) this.clearForm();
    },
    client() {
      this.brand = null;
      this.forceUpdateBrands++;
    },
    content: {
      immediate: true,
      handler(newContent) {
        if (newContent) {
          const { name, imageUrl, iconUrl, brand, color, isBudgetEnabled } =
            newContent;
          this.name = name;
          this.imageUrl = imageUrl;
          this.iconUrl = iconUrl;
          this.client = brand?.client;
          this.isBudgetEnabled = isBudgetEnabled;
          this.color = color ?? DEFAULT_OBJECT_MARKER_COLOR;

          if (iconUrl) {
            this.showCustomIcon = true;
          }

          this.$nextTick(() => {
            this.brand = brand;
          });
        }
      },
    },
    showCustomIcon() {
      if (!this.showCustomIcon) {
        this.iconUrl = null;
      }
    },
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
    extend('regex', {
      ...regex,
      message: this.$t('errors.error-color-regex'),
    });
  },
  methods: {
    async handleReadFile(file) {
      const svg = await readFileAsText(file);
      const wrappedSvg = drawSvgInsideRectangle(svg, CUSTOM_MARKER_OPTIONS);
      const updatedFile = svgStringToFile(wrappedSvg, file.name);
      const { url } = await uploadTemporaryFile(updatedFile);
      this.iconUrl = url;
    },

    pushFormData() {
      const formData = {
        name: this.name,
        color: this.color,
        isBudgetEnabled: this.isBudgetEnabled,
        ...(this.imageUrl && { imageUrl: this.imageUrl }),
        ...(this.iconUrl && { iconUrl: this.iconUrl }),
        ...(!this.isEdit && { brandId: this.brand?.id }),
      };

      this.$emit('on-submit', formData);
    },
    clearForm() {
      this.name = '';
      this.imageUrl = '';
      this.iconUrl = '';
      this.color = DEFAULT_OBJECT_MARKER_COLOR;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
  setup() {
    return { hexColorRegex, LabelPosition, mdiAlertOctagonOutline };
  },
};
</script>
