<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-12">
      <breadcrumbs
        :path="[
          {
            name: $t('object-types-view.heading'),
            url: { name: 'GeneralObjectTypes' },
          },
          { name: $t('edit-object-type-view.edit-object-type') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>

      <object-type-form
        id="object-typeForm"
        class="mb-64"
        is-edit
        :content="objectType"
        :clear="submissionEndSuccessfully"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="object-typeForm"
            class="w-48 mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push({ name: 'GeneralObjectTypes' })">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import ObjectTypeForm from '@/views/Dashboard/ObjectTypes/Forms/ObjectTypeForm.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'EditObjectType',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    ObjectTypeForm,
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: {
    ...mapState('object', ['objectType']),
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch(
      'object/fetchObjectTypeDetails',
      to.params.objectTypeId,
    );
    next();
  },
  methods: {
    ...mapActions('object', [
      'updateObjectType',
      'deleteObjectTypeImage',
      'deleteObjectTypeIcon',
    ]),
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        if (this.objectType.imageUrl && !formData.imageUrl) {
          await this.deleteObjectTypeImage(this.objectType.id);
        }

        if (this.objectType.iconUrl && !formData.iconUrl) {
          await this.deleteObjectTypeIcon(this.objectType.id);
        }

        await this.updateObjectType({
          objectTypeId: this.objectType.id,
          data: formData,
        });

        this.$toast.success(
          this.$t('edit-object-type-view.updated-successfully'),
        );
        if (this.redirectAfterSubmission)
          this.$router.push({ name: 'GeneralObjectTypes' });

        this.submissionEndSuccessfully = true;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>
